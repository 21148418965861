import React from 'react'
import useTranslation from '@/i18n'
import { remove } from '@/api/zones'
import { useErr, useModal, useProgress } from '@/hooks'

type Props = {
  zoneId: number
  zoneName: string
  reload: () => Promise<void>
}

export const Delete = ({ zoneId, zoneName, reload }: Props) => {
  const { start, stop, inProgress } = useProgress()
  const { closeModal } = useModal()
  const { setErr } = useErr()
  const { t } = useTranslation()

  const del = () => {
    closeModal()
    start(0)
    remove(zoneId)
      .then(() => {
        reload()
      })
      .catch((err) => {
        setErr(err.message, err.status)
        stop()
      })
  }

  return (
    <div>
      <div className="px-4">
        <h1 className="text-xl text-red-600 font-bold mb-2 max-w-3xl break-words">
          {t('Deleting {{name}}', { name: zoneName })}
        </h1>

        <p className="text-gray-800 mb-4">{t('ConfirmDeleting')}</p>
      </div>
      <div className="bg-gray-100 px-2 py-2 text-right">
        <button
          className={`${
            inProgress ? 'bg-red-300' : 'bg-red-600'
          } text-white px-8 rounded-sm focus:outline-none`}
          onClick={del}
          autoFocus={true}
        >
          {t('Yes')}
        </button>
      </div>
    </div>
  )
}
