import { axios, getAuthorization } from '@/api/axios'
import { withRefreshToken } from '@/auth'
import { errConverter } from './error'

const postZone = (newZone?: string): Promise<string> =>
  new Promise<string>((resolve, reject) => {
    axios
      .post<{ message: string }>(`dev/zone`, newZone, {
        headers: getAuthorization(),
      })
      .then((result) => {
        resolve(result.data.message)
      })
      .catch((err) => {
        reject(errConverter(err))
      })
  })

const post = withRefreshToken(postZone)

export { post }
