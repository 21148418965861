import React from 'react'
import { IoShieldCheckmark, IoAlertCircle } from 'react-icons/io5'

export const Dnssec = ({ dnssec }: { dnssec?: string }) => {
  if (dnssec == null) {
    return (
      <span className="flex items-center px-2 text-xs leading-5 font-semibold rounded-full text-gray-400">
        checking
      </span>
    )
  }

  if (dnssec === 'Inactive') {
    return (
      <span className="flex items-center px-2 text-xs leading-5 font-semibold rounded-full text-red-600">
        <IoAlertCircle className="text-xl" />
      </span>
    )
  }

  return (
    <span className="flex items-center px-2 text-xs leading-5 font-semibold rounded-full text-green-600">
      <IoShieldCheckmark className="text-xl" />
    </span>
  )
}
