import { axios, getAuthorization } from '@/api/axios'
import { withRefreshToken } from '@/auth'

export const getTrustChain = (domainName?: string): Promise<string | any> =>
  new Promise<string>((resolve, reject) => {
    axios
      .get<{ message: string }>(`dev/trust-chain`, {
        headers: getAuthorization(),
        params: {
          domainName,
        },
        'axios-retry': {
          retries: 0,
        },
      })
      .then((result) => {
        resolve(result.data.message)
      })
      .catch((err) => {
        reject(err)
      })
  })

const get = withRefreshToken(getTrustChain)

export { get }
