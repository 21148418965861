import React, { useState, useRef } from 'react'
import useTranslation from '@/i18n'
import { post } from '@/api/zone'
import { useErr, useProgress, useTooltip } from '@/hooks'

type Props = {
  loadZone: () => Promise<void>
}

export const AddNewZone = ({ loadZone }: Props) => {
  const { inProgress, start, stop } = useProgress()
  const refZone = useRef<HTMLInputElement | null>(null)
  const [value, setValue] = useState('')
  const [result, setResult] = useState('')
  const { setTooltip } = useTooltip()
  const { setErr } = useErr()
  const { t } = useTranslation()

  const add = () => {
    if (value.length === 0) {
      setResult(t('Enter a zone name'))
      refZone.current?.focus()
      return
    }
    setResult('')
    start(0)

    post(value)
      .then(() => {
        loadZone().then(() => {
          setValue('')
          refZone.current?.focus()
          setTooltip('Completed')
        })
      })
      .catch((err) => {
        if (err.status == null) {
          setErr(err.message)
        } else {
          setResult(`[StatusCode: ${err.status}] ${t(err.message)}`)
        }
        stop()
        refZone.current?.focus()
      })
  }

  const keyDown = (e) => {
    if (e.keyCode === 13) {
      add()
    }
  }

  return (
    <div className="p-4 w-full">
      <h1 className="text-gray-600 font-bold mb-4">{t('Add a new zone')}</h1>
      <input
        className="outline-none border-b border-gray-300 rounded-sm w-full px-1 text-sm mb-1"
        type="text"
        disabled={inProgress}
        maxLength={253}
        placeholder={t('sample.com')}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        autoFocus={true}
        ref={refZone}
        onKeyDown={keyDown}
      />
      <p
        className={`text-red-600 text-xs mb-2 h-12 break-words w-full ${
          result.length > 0 ? '' : 'invisible'
        }`}
      >
        {result}
      </p>
      <div className="text-right">
        <button
          className={`${
            inProgress ? 'bg-gray-400' : 'bg-blue-600'
          } text-white w-full sm:w-32 rounded-sm focus:outline-none`}
          onClick={add}
        >
          {t('Add')}
        </button>
      </div>
    </div>
  )
}
