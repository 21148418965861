import { axios, getAuthorization } from '@/api/axios'
import { withRefreshToken } from '@/auth'
import { errConverter } from '../error'

export type Ds = {
  ds: string
  ttl: string
}

export type Zone = {
  id: number
  name: string
  customer_id: string
  createdAt: string
  updatedAt: string
  mode?: number
  dsList: Ds[]
}

const getZones = (): Promise<Zone[]> =>
  new Promise<Zone[]>((resolve, reject) =>
    axios
      .get<{ message: string }>(`/dev/zone/list`, {
        headers: getAuthorization(),
      })
      .then((res) => {
        const parsed: Zone[] = JSON.parse(res.data.message)
        resolve(parsed)
      })
      .catch((err) => {
        reject(errConverter(err))
      })
  )

const get = withRefreshToken(getZones)

export const removeZones = (zoneId?: number): Promise<string> =>
  new Promise<string>((resolve, reject) =>
    axios
      .delete<{ message: string }>(`/dev/zones`, {
        headers: getAuthorization(),
        data: {
          zoneId,
        },
      })
      .then((result) => {
        resolve(result.data.message)
      })
      .catch((err) => {
        reject(errConverter(err))
      })
  )

const remove = withRefreshToken(removeZones)

export { get, remove }
