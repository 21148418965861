import { axios, getAuthorization } from '@/api/axios'
import { withRefreshToken } from '@/auth'
import { errConverter } from '../error'

export type CheckDomain = {
  requireChangeMode: string
  id: string
}

const getCheckDomain = (zoneId?: number): Promise<CheckDomain> =>
  new Promise<CheckDomain>((resolve, reject) =>
    axios
      .get<{ message: string }>(`/dev/zones/check-domain`, {
        headers: getAuthorization(),
        params: {
          zoneId,
        },
      })
      .then((res) => {
        const parsed: CheckDomain = JSON.parse(res.data.message)
        resolve(parsed)
      })
      .catch((err) => {
        reject(errConverter(err))
      })
  )

const get = withRefreshToken(getCheckDomain)

export { get }
