import { axios, getAuthorization } from '@/api/axios'
import { withRefreshToken } from '@/auth'
import { errConverter } from './error'

type DsUpdateResult = {
  updated: boolean
}

const postDsUpdate = (_?: number): Promise<DsUpdateResult> =>
  new Promise<DsUpdateResult>((resolve, reject) => {
    axios
      .post<{ message: string }>(
        `dev/ds-update`,
        {},
        {
          headers: getAuthorization(),
        }
      )
      .then((result) => {
        resolve(JSON.parse(result.data.message))
      })
      .catch((err) => {
        reject(errConverter(err))
      })
  })

const post = withRefreshToken(postDsUpdate)

export { post }
