import React, { useEffect } from 'react'
import useTranslation from '@/i18n'
import { remove } from '@/api/zones'
import { useErr, useModal, useProgress } from '@/hooks'

type Props = {
  zoneName: string
}

export const ExistsSubdomain = ({ zoneName }: Props) => {
  const { stop, inProgress } = useProgress()
  const { closeModal } = useModal()
  const { t } = useTranslation()

  useEffect(() => {
    stop()
  }, [])

  return (
    <div>
      <div className="px-4">
        <h1 className="text-xl text-red-600 font-bold mb-2 max-w-3xl break-words">
          {t('Failed to delete {{name}}', { name: zoneName })}
        </h1>

        <p className="text-gray-800 mb-4">{t('ExistSubdomain')}</p>
      </div>
      <div className="bg-gray-100 px-2 py-2 text-right">
        <button
          className={`${
            inProgress ? 'bg-red-300' : 'bg-red-600'
          } text-white px-8 rounded-sm focus:outline-none`}
          onClick={() => {
            closeModal()
          }}
          autoFocus={true}
        >
          {t('I got it')}
        </button>
      </div>
    </div>
  )
}
