import React from 'react'
import { GoAlert } from 'react-icons/go'
import useTranslation from '@/i18n'
import { useModal } from '@/hooks'

type Props = {
  id: string
}

const ChangeMode = (props: Props) => {
  const { t } = useTranslation()
  const { closeModal } = useModal()

  return (
    <div className="w-108">
      <div className="px-4">
        <div className="flex items-center">
          <GoAlert className="text-xl text-yellow-500 mr-2" />
          <h1 className="text-xl text-red-500 font-medium ">
            {t('Change QUALITA DOMAIN configuration')}
          </h1>
        </div>
        <p className="text-gray-700 text-sm mt-4">
          {t('ExplanationChangeConfig')}
        </p>
      </div>
      <div className="w-full text-right mt-4 p-2 bg-gray-100">
        <a
          className="bg-blue-500 px-4 py-1 text-white"
          href={`${process.env.QT_DOMAIN_URL}/domain-list/config?id=${props.id}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={closeModal}
        >
          {t('Open QUALITIA DOMAIN')}
        </a>
      </div>
    </div>
  )
}

export default ChangeMode
