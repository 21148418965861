import * as Sentry from '@sentry/browser'
import React, { useEffect } from 'react'
import { withLayout } from '@/layout'
import useTranslation from '@/i18n'
import { get, Zone } from '@/api/zones'
import { get as trustChain } from '@/api/trustChain'
import {
  useProgress,
  useWaitSignIn,
  useRightBar,
  useModal,
  useErr,
  useSafeState,
} from '@/hooks'
import { Delete, AddNewZone, ExistsSubdomain } from '@/components/Zones'
import Table from '@/components/Table'
import Header from '@/components/Zones/Header'
import ZoneData, { ZoneDnssec } from '@/components/Zones/ZoneData'
import Reload from '@/components/Reload'
import { post as postDsUpdate } from '@/api/ds-update'
import { get as checkDomain } from '@/api/zones/check-domain'
import ChangeMode from '@/components/Zones/ChangeMode'

const Zones = () => {
  const { inProgress, start, stop } = useProgress()
  const { isOpenRightBar, openRightBar } = useRightBar()
  const { openModal } = useModal()
  const { t, d } = useTranslation()
  const [error, setError] = useSafeState<string | null>(null)
  const [zones, setZones] = useSafeState<ZoneDnssec[]>([])
  const { setErr } = useErr()

  const waitSignIn = useWaitSignIn()

  const load = () => {
    start()

    postDsUpdate()
      .then(({ updated }) => {
        if (updated) {
          loadZones()
        }
      })
      .catch((err) => {
        setErr(err.message, err.status)
      })

    return loadZones()
  }

  const loadZones = () => {
    start()

    return get()
      .then((zones) => {
        setZones(zones)
        if (zones.length === 0) {
          setError('There is not a zone')
        } else {
          setError(null)
        }
        stop()

        checkDnssec(zones).then((zone) => {
          setZones(zone)
        })
      })
      .catch((err) => {
        stop()
        setErr(err.message, err.status)
      })
  }

  useEffect(() => {
    waitSignIn(load)

    return () => {
      stop()
    }
  }, [])

  const checkDnssec = (zones: Zone[]) =>
    Promise.all(
      zones.map((zone) =>
        trustChain(zone.name)
          .then(() => {
            return { ...zone, dnssec: 'Active' }
          })
          .catch((err) => {
            if (err.response != null && err.response.status === 400) {
              if (err.response.data.message === 'Failed') {
                return { ...zone, dnssec: 'Inactive' }
              }
            }
            return { ...zone, dnssec: 'checking' }
          })
      )
    )

  const del = (id: number, name: string) => {
    if (!inProgress) {
      start(0)
      checkDomain(id)
        .then(({ requireChangeMode, id: domainId }) => {
          if (requireChangeMode) {
            openModal(<ChangeMode id={domainId} />)
          } else {
            openModal(<Delete zoneId={id} zoneName={name} reload={load} />)
          }
          stop()
        })
        .catch((err) => {
          openModal(<ExistsSubdomain zoneName={name} />)
        })
    }
  }

  return (
    <>
      <div className="sm:flex items-center mb-4">
        <h1 className="text-gray-700 text-base md:text-2xl font-bold mr-8 whitespace-no-wrap">
          {t('Zone list')}
        </h1>
        <button
          disabled={isOpenRightBar}
          className={`px-4 text-white whitespace-no-wrap text-sm md:text-base rounded-sm focus:outline-none ml-auto ${
            isOpenRightBar || inProgress ? 'bg-gray-300' : 'bg-red-600'
          }`}
          onClick={() => openRightBar(<AddNewZone loadZone={load} />)}
        >
          {t('Add new zone')}
        </button>
      </div>
      <Reload load={load} />
      <Table
        header={Header}
        listData={zones}
        list={(zone, _) => ZoneData({ zone, inProgress, del, d })}
      />

      {error != null && (
        <div>
          <p className="text-2xl text-gray-500 text-center mt-10">{t(error)}</p>
        </div>
      )}
    </>
  )
}

const ZonesPage = withLayout(Zones)

export default ZonesPage
