import React from 'react'
import { Zone } from '@/api/zones'
import Link from '@/components/Link'
import { Dnssec } from '@/components/Zones'
import { IoTrash } from 'react-icons/io5'
import DsList from './DsList'

export type ZoneDnssec = Zone & {
  dnssec?: string
}

type Props = {
  zone: ZoneDnssec
  inProgress: boolean
  del: (id: number, name: string) => void
  d: (key: string, date: Date) => string
}

const ZoneData = ({ zone, inProgress, del, d }: Props) => {
  return (
    <tr key={zone.id}>
      <td className="px-6 py-4 ">
        <Link
          to={`/zone/rrs?zoneId=${zone.id}&zoneName=${zone.name}`}
          className="break-words text-sm text-gray-900 font-medium hover:text-blue-400"
        >
          {zone.name}
        </Link>
      </td>
      <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
        {d('ymdhms', new Date(zone.createdAt))}
      </td>
      <td className="px-6 py-4 w-20 truncate">
        <div className="sm:flex sm:items-center">
          <Dnssec dnssec={zone.dnssec} />
          <DsList dsList={zone.dsList} />
        </div>
      </td>
      <td className="py-4 whitespace-nowrap">
        <IoTrash
          className={`text-xl ${
            inProgress ? 'text-gray-300' : 'text-gray-600 cursor-pointer '
          } mx-auto`}
          onClick={() => del(zone.id, zone.name)}
        />
      </td>
    </tr>
  )
}

export default ZoneData
