import React from 'react'
import { Ds } from '@/api/zones'
import useTranslation from '@/i18n'
import { useModal } from '@/hooks'
import { convertSeconds } from '@/utils'
import DsConfirmDialog from './DsConfirmDialog'

type Props = {
  dsList: Ds[]
}

const DsList = ({ dsList }: Props) => {
  const { openModal } = useModal()
  const { t } = useTranslation()

  return (
    <>
      {dsList.length > 0 && (
        <div
          className="bg-blue-600 text-white rounded-lg px-2 text-xs cursor-pointer mt-1 sm:mt-0 sm:ml-2 inline-block"
          onClick={() =>
            openModal(<DsConfirmDialog dsList={dsList} showInfo={false} />)
          }
        >
          {t('DS')}
        </div>
      )}
    </>
  )
}

export default DsList
