import React from 'react'
import { PublishDs } from '@/api/manual-start-zone'
import useTranslation from '@/i18n'
import { useModal } from '@/hooks'
import { convertSeconds } from '@/utils'

type Props = PublishDs & {
  showInfo: boolean
}

const DsConfirmDialog = ({ dsList, showInfo }: Props) => {
  const { closeModal } = useModal()
  const { t } = useTranslation()
  return (
    <div className="pt-2 px-4 pb-4">
      <h1 className="font-medium text-lg px-1 text-gray-800 border-b border-gray-300 mb-4">
        {t('DS record')}
      </h1>
      <ul className="">
        {dsList.map(({ ds, ttl }, index) => {
          return (
            <li className="mb-2" key={index}>
              <p>{t('SetDSLater', { ...convertSeconds(Number(ttl)) })}</p>
              <p className="bg-gray-800 text-white py-1 rounded-sm px-2 break-all">
                {ds}
              </p>
            </li>
          )
        })}
      </ul>

      <div className="my-2 w-full sm:w-108">
        <p className="font-medium text-sm">{t('Note')}</p>
        <p className="text-sm px-2 py-1 bg-blue-100 rounded-md">
          {t('NoteEnableDNSSEC')}
        </p>
      </div>
      <div className="sm:flex sm:items-center">
        {showInfo && (
          <p className="text-xs text-gray-800 ">
            {t(
              'You can see the DS records on zone list page after closing this dialog'
            )}
          </p>
        )}
        <button
          className="bg-blue-500 text-white w-full sm:ml-auto sm:w-32"
          onClick={closeModal}
        >
          {t('Close')}
        </button>
      </div>
    </div>
  )
}

export default DsConfirmDialog
