export const absoluteDomain2relativeDomain = (
  domain: string,
  zoneName: string
): string => {
  const isAbsoluteDomain = checkAbsoluteDomain(domain)
  const includeDot = includeLetter(domain, '.')

  if (includeDot) {
    return isAbsoluteDomain ? domain.slice(0, -1) : domain
  }

  return `${domain}.${zoneName}`
}

const checkAbsoluteDomain = (domain: string): boolean => {
  return domain.endsWith('.')
}

const includeLetter = (target: string, searchString: string): boolean => {
  return target.includes(searchString)
}

export const defaultSvc = {
  priority: '',
  targetName: '.',
  svcParams: {
    mandatories: [],
    alpn: [''],
    ipv4hints: [''],
    ipv6hints: [''],
  },
}

export const border = (value: string, error: string, required?: boolean) =>
  (required == null && value === '') || error !== ''
    ? 'border-red-500'
    : 'border-gray-500'

export const CREATE_NEW_SERVICE = -1
export const MAX_SERVICE_NUM = 32

export * from './time'
// export * from './user'
